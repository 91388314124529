import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/vercel/path0/app/preload-resources.js");
;
import(/* webpackMode: "eager", webpackExports: ["Client"] */ "/vercel/path0/helpers/client/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["StoreProvider"] */ "/vercel/path0/helpers/store.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@storyblok/react/dist/bridge-loader.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/@storyblok/react/dist/story.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Benne\",\"arguments\":[{\"weight\":[\"400\"],\"subsets\":[\"latin\"],\"variable\":\"--font-benne\",\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"benne\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Be_Vietnam_Pro\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"subsets\":[\"latin\"],\"variable\":\"--font-be-vietnam\",\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"beVietnam\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/index.css");
